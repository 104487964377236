import React, { useEffect } from 'react';
import get from 'lodash.get';
import { injectIntl } from 'gatsby-plugin-react-intl';
import { Spacing, SharedStyles, Container } from '../../kenra-storybook/index';
import Button from '../../kenra-storybook/components/atoms/AllButtonAlt';
import { navigate } from 'gatsby-link';

const { StTitle, StSectionInfo } = SharedStyles;

const RequestSubmitted = ({ page, intl }) => {
    useEffect(() => {
        setTimeout(() => {
            navigate('/education');
        }, 5000);
    }, []);
    let background;
    if (page) {
        background = page.background;
    }
    return (
        <>
            <div style={{ backgroundColor: '#FFF' }}>
                {background && (
                    <Spacing
                        style={{
                            minHeight: '400px',
                            backgroundSize: 'auto',
                            backgroundImage: `url("${get(
                                background,
                                'localFile.publicURL'
                            )}")`,
                        }}
                    ></Spacing>
                )}

                <Spacing
                    style={{
                        minHeight: '400px',
                    }}
                >
                    <Spacing>
                        <StSectionInfo>
                            <Container>
                                <StTitle colorBlack styleMedium>
                                    <h2>
                                        {
                                            'THANK YOU FOR YOUR REGISTRATION WE’LL SEE YOU SoON.'
                                        }
                                    </h2>
                                </StTitle>
                            </Container>
                        </StSectionInfo>
                    </Spacing>
                    <Spacing>
                        <Button wide variant={2} href={'/education'}>
                            Back
                        </Button>
                    </Spacing>
                </Spacing>
            </div>
        </>
    );
};

export default injectIntl(RequestSubmitted);
