import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import RequestSubmitted from 'components/CustomLanding/InSalonRequestSubmitted';

export default function InSalonRequestSubmitted(props) {
    let {
        data: { allContentfulEducatorApplication },
    } = props;
    let item = allContentfulEducatorApplication.nodes[0];
    return (
        <Layout>
            <RequestSubmitted page={item} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        allContentfulEducatorApplication(
            filter: {
                id: { in: ["559449bc-3228-50a3-87bc-38ce5376a89f"] }
                node_locale: { eq: $locale }
            }
        ) {
            nodes {
                background {
                    localFile {
                        publicURL
                    }
                }
            }
        }
    }
`;
